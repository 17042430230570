import React from 'react';
import {withPrefix} from 'gatsby';
import Img from 'gatsby-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import {usePublicInformation} from '../../graphql/public-information/publicInformation';
import './PublicInformationContent.less';

const PublicInformationContent = () => {
  const publicInformation = usePublicInformation();

  return (
    <Container className="publicInformation">
      <h1 className="publicInformation__heading mb-1">
        Informații destinate publicului
      </h1>
      <div className="publicInformation__borderBottom mb-4" />
      <Row>
        {publicInformation.map((information, index) => (
          <Col sm={12} md={6} lg={4} className="mx-auto my-3" key={index}>
            <a
              href={withPrefix(information.link)}
              target="_blank"
              rel="noreferrer"
            >
              <Card className="publicInformation__card">
                <Img
                  fluid={information.image.childImageSharp.fluid}
                  alt={information.title}
                  className="publicInformation__card-image card-img"
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    <span>{information.title}</span>
                  </Card.Title>
                </Card.Body>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default PublicInformationContent;
