import React from 'react';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo';
import PublicInformationContent from '../components/publicInformationContent/PublicInformationContent';

const Information = () => {
  return (
    <Layout>
      <SEO title="Informații destinate publicului" />
      <PublicInformationContent />
    </Layout>
  );
};

export default Information;
