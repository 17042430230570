import {useStaticQuery, graphql} from 'gatsby';

export const usePublicInformation = () => {
  const {allPublicInformationJson} = useStaticQuery(
    graphql`
      query PublicInformation {
        allPublicInformationJson {
          nodes {
            title
            link
            image {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `,
  );
  return allPublicInformationJson.nodes;
};
